import React, { useState }  from "react";
import styled from 'styled-components';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';

import { getReportById } from '../services/whitelabel-api';
import { isSSR } from '../utils/renderContext';

import { useJourneyContext } from '../components/Contexts/JourneyContext/JourneyContext';

import Container from '../components/Container';
import Card from '../components/Card/';
import ProgressBar from '../components/SurveyJsJourney/ProgressBar/';
import Button from '../components/Buttons/Button';

import Icon from '../components/svg';
import useText from '../hooks/data/useText';

const NO_REPORT_REDIRECT_LOCATION = "/";

const CompletePage = ({ location }) => {
  const text = useText();

  const [response, setResponse] = useState(location.state?.response);
  const journeyContext = useJourneyContext();

  const pages = journeyContext?.journey?.surveyConfig?.surveyJson?.pages;
  
  const categories = Object.values(pages?.reduce((acc, page, index) => {
      const label = page?.name?.split('-')[0];
      return {
        ...acc,
        [label]: {
          ...(acc[label] || {}),
          label,
          step: (typeof acc[label]?.step !== 'undefined') ? acc[label].step : Object.keys(acc).length,
          firstQNum: (typeof acc[label]?.firstQNum !== 'undefined') ? acc[label]?.firstQNum : index,
          lastQNum: index
        }
      }
    }, {}))
    .filter(category => category.label !== 'uncategorised')
    .sort((a, b) => a.step - b.step);

  

  if(isSSR()) return null;
  
  if(!response) {
    // check for encryptedTmRef
    const queryParams = queryString.parse(window.location.search);
    const encryptedTmRef =  queryParams?.id;
    if(!encryptedTmRef) navigate('/'); // no id boot to journey
    // call service with id
    getReportById(encryptedTmRef, journeyContext.source.appId).then((reportRes) => {
      setResponse(reportRes?.data);
    })
    .catch(() => navigate(NO_REPORT_REDIRECT_LOCATION))
    return null;
  }
  
  const report = response?.report;
  const redirectUrl = response?.id ? `/advice?id=${response?.id}&appId=${response?.appId}` : report?.reportUrl;

  return (
    <CompletePageWrap>
      <ProgressBar steps={categories} activeQ={Number.MAX_SAFE_INTEGER} />
      <Container>
          <CompleteCard className="mt-4 mt-lg-5">

            <HiP>Hi {report?.person.forename}</HiP>

            <Icon name="tick" height={48} width={48} className="mb-3" />

            <div className="mb-2">{text.header}</div>
            <div>{text.callFrom}</div>
            
            <CallCentreNumber>{report?.callCentrePhoneNumber}</CallCentreNumber>
            
            <p><b>{text.subheader}</b></p>

            <DesktopBtn className="btn" onClick={() => navigate(redirectUrl, { state: { report } })}>{text.getReportBtn}</DesktopBtn>

          </CompleteCard>

          <MobileBtn className="btn" onClick={() => navigate(redirectUrl, { state: { report } })}>{text.getReportBtn}</MobileBtn>

      </Container>

    </CompletePageWrap>    
  )
}

const MobileBtn = styled(Button)`
  margin: ${props => props.theme.spacing.base * 6}px auto;
  width: 100%;
  max-width: 457px;
  display: block;
  @media (min-width: 767px) {
    display: none;
  }
`;

const DesktopBtn = styled(Button)`
  display: none;
  width: 100%;
  @media (min-width: 767px) {
    display: inline-block;
  }
`;


const CompletePageWrap = styled.div`
  height: 100%;
`;

const CompleteCard = styled(Card)`
  max-width: 457px;
  margin: 0 auto;
  padding: ${props => props.theme.spacing.base * 3}px;
  @media (min-width: 767px) {
    padding: ${props => props.theme.spacing.base * 5}px;
  }
`;

const HiP = styled.p`
  font-size: 21px;
  font-weight: 500;
`;

const CallCentreNumber = styled.div`
  font-size: 21px;
  font-weight: 500;
  padding-bottom: ${props => props.theme.spacing.base * 3}px;
`;


export default CompletePage;
